@import "../../../CSS/variables.scss";

.container--not-found {
    height: 100%;
    width: 100%;
    @include displayFlex(center, center, row);
}

.sub--container--not-found {
    height: 100%;
    width: 100%;
    background: $White-color;
    max-width: 1140px;
    padding: 25px;
    @include displayFlex(center, center, column);
}

.fontHeading2 {
    @include semiboldFontFamily;
    font-size: var(--fs-font3) !important;
    margin: 50px 0 10px 0 !important;
}

.subheading {
    @include regularFontFamily;
    margin: 10px 0 !important;
}

.backbutton {
    margin-top: 26px !important;
    height: 54px;
    width: 157px;
    color: $White-color !important;
}