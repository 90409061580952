@import "./CSS/variables.scss";
@import "./Styles/global.scss";



.nav-link {
  font-size: var(--fs-h6);
  padding: 0 1rem !important;
  text-decoration: none !important;
}

.nav-link a {
  text-decoration: none !important;
}

a:hover {
  text-decoration: none;
}

.header-styles {
  height: 100vh;
}

hr {
  border: 1px solid $purple-color-1;
}

li {
  margin-bottom: 12px;
}

@media (max-width: 575px) {
  .enroll-btn {
    margin-top: 1rem;
  }
}

.div-col {
  width: 100%;
  flex: 1 !important;
  padding: 0;
  max-width: 100% !important;
}

.carousel-item img {
  width: 100%;
}

.carousel-caption {
  right: 35%;
  top: 35%;
}

@media (max-width: 750px) {
  .carousel-caption {
    top: 20%;
  }
}

@media (max-width: 550px) {
  .carousel-caption {
    width: 80%;
  }

  .carousel-caption h1 {
    font-size: var(--fs-font1);
  }
}

/* Footer.css */
.footer {
  background-color: $light-color;
  padding: 20px 0;
}

.footer p {
  margin: 0;
}

@media (max-width: 576px) {
  .footer {
    text-align: center;
  }

  .footer p {
    margin-bottom: 10px;
  }
}

/* Banner.css */
.banner {
  position: relative;
  background: url("https://raw.githubusercontent.com/TidbitsJS/University-Website/d54187818bc4ae4c579f518dda823a7cbce3b64a/src/images/slide-01.jpg") no-repeat center center fixed;
  @include background-size(cover);
}

.banner-overlay {
  background-color: rgba(0, 0, 0, 0.6);
  padding: 40px;
  color: $White-color;
}

.cardCenter {
  @include displayFlex(flex-start, center, column);
  height: 100%;
}

.cardTitle {
  @include semiboldFontFamily;
  font-size: var(--fs-h2) !important;
  line-height: normal;
  color: $dark-color;
}

.cardTitle span {
  color: $primary-color-3;
  margin-bottom: 25px;
}

.cardDescription {
  @include mediumFontFamily;
  font-size: var(--fs-body) !important;
  line-height: var(--line-height-sm) !important;
  color: $dark-color;
  text-transform: unset !important;
}

.realTimeBG {
  background: $Linear-Gradient3,
    url(./images/mMatrix_Real-time_Sentiment_Analysis_BG_New.png);
  @include background-size(100% 100%);
}

.dataCollectionBG {
  background: $Linear-Gradient3,
    url(./images/mMatrix_Engage_your_Data_Empower_your_Business_BG_New.png);
  @include background-size(100% 100%);
}

.blogsSection_BG {
  padding: 70px 0 70px;
  background: $Linear-Gradient3, url(./images/Blogs_BG.png);
  background-repeat: no-repeat;
  @include background-size(100% 100%);
}

.dashboards_reportings_BG {
  background: $Linear-Gradient3,
    url(./images/mMatrix_Compare_Your_Brand_To_The_Competition_BG_New2.png);
  @include background-size(100% 100%);
}

.industryExpertiseBG {
  background: $Linear-Gradient3, url(./images/Industry_Expertise_BG.png);
  @include background-size(100% 100%);
}

/* === BAnner === */
.bannerUpper {
  padding: 50px 80px;
  background: linear-gradient($light-color-12, $light-color-12),
    url(./images/mMatrix_Maximize_the_Potential_of_your_Business_withmMatrix_SolutionsBG_New.png);
  @include background-size(100% 100%);
  border-bottom: 2px solid $light-color-2;
  position: relative;
}

.unlease_power_mpulse_bg {
  background: $Linear-Gradient3,
    url(./images/mMatrix_Real-time_Sentiment_Analysis_BG_New.png);
  @include background-size(100% 100%);
  padding: 4.5rem 0 2.5rem 0;
}

.faqBG {
  background: $Linear-Gradient3, url(./images/mMatrix_FAQs_BG_New.png);
  @include background-size(100% 100%);
  padding: 5rem 0rem;
}

.footerBG {
  padding: 8px 20px 0;
  background: url(./images/Footer/footer_mobile_bg_mb.png) bottom center;
  background-repeat: no-repeat;
  background-size: 100vw calc(55% + 35vw);
  background-position: bottom;
  -moz-background-size: cover;
  -o-background-size: cover;
}

.footer_div {
  width: 100% !important;
  height: auto !important;
  padding-top: 1rem !important;
}

.bannerUpper p {
  @include mediumFontFamily;
  font-size: var(--fs-body);
  line-height: var(--line-height-sm);
  text-align: center;
  color: $dark-color;
  margin-bottom: 1rem;
  text-transform: unset !important;
}

.flexAlignCenterNew {
  @include displayFlex(center, center, row);
  margin-bottom: 1rem;
}

.alignCards {
  @include displayFlex(center, center, row);
  margin-bottom: 1rem;
  width: 100%;
  position: relative;
}

.cardBanner {
  border-radius: var(--br-secondary);
  border: 3px solid $light-color-3 !important;
  background: $Linear-Gradient;
  background-color: $primary-color-3;
  padding: 20px;
  height: 100%;
  transition: background 1.5s !important;
  -webkit-transition: background 1.5s ease-in-out;
  min-height: 15rem;
}

.cardBanner:hover {
  border: 3px solid rgba(210, 182, 255, 0.3);
  background: $primary-color-3;
  box-shadow: $Box-Shadow1;
}

@media screen and (min-width: 1920px) {
  .cardsContainer {
    max-width: 1440px;
  }
}

@media screen and (min-width: 1024px) {
  .cardsContainer {
    max-width: 1340px;
  }
}

@media screen and (min-width: 990px) {
  .cardBanner {
    padding: 20px;
    height: 100% !important;
    width: calc(18rem + 7vw);
    background: $White-color;
    transition: background 1.5s !important;
    -webkit-transition: background 1.5s ease-in-out;
  }

  .alignCards {
    @include displayFlex(flex-start, center, row);
    margin-bottom: 1rem;
    width: 100%;
    position: relative;
    margin-top: 2rem;
  }

  .cardSectionRow {
    width: 100%;
    justify-content: space-around;
  }
}

.bannerText {
  width: 100%;
  padding: 0 4vw;
}

@media screen and (min-width: 1024px) {
  .bannerText {
    width: 100%;
    padding: 0 14vw;
  }
}

@media screen and (min-width: 1440px) {
  .bannerText {
    padding: 0 4%;
  }
}

.cardHeadDiv {
  @include displayFlex(center, flex-start, row);
}

.cardHeadDiv .cardIconCSS {
  border-radius: var(--br-primary);
  border: 3px solid $light-color-3;
  padding: 15px;
  width: 80px;
}

.cardBanner .card-icon,
.cardIconCSS .card-icon {
  width: 50px;
  min-width: 50px;
}

.cardHeadDiv .cardTitle {
  color: $dark-color;
  font-size: 22px !important;
  @include mediumFontFamily;
  line-height: var(--line-height-xl);
  margin: 0 0 0 16px;
}

.cardBanner:hover .card-icon {
  filter: brightness(0) invert(1);
}

.card-icon.white-icon {
  filter: brightness(0) invert(0);
  -webkit-filter: brightness(0) invert(0);
}

.cardBanner .cardDesc {
  color: $dark-color-5;
  font-size: var(--fs-body) !important;
  @include mediumFontFamily;
  line-height: var(--line-height-sm);
  text-align: left;
  margin-top: 1rem;
}

.cardBanner:hover .cardDesc,
.cardBanner:hover .cardTitle {
  color: $White-color;
}

.cardTitle1 {
  @include mediumFontFamily;
  font-size: 28px !important;
  line-height: 30px !important;
  color: $dark-color;
  margin-left: 0px;
}

.cardBanner:hover .cardTitle1 {
  color: $White-color;
}

.cardSection {
  margin: 0.625rem 0rem;
}

.row-gutter-x-y-none {
  --bs-gutter-x: 0 !important;
  --bs-gutter-y: 0 !important;
}

.sectionHeadDiv {
  @include displayFlex(center, flex-start, row);
}

.sectionHeadDiv .sectionIconCSS {
  border-radius: var(--br-primary);
  border: 2px solid $purple-color-1;
  background: $White-color;
  box-shadow: $Box-Shadow5;
  padding: 12px;
  margin: 0 16px 0 0px;
}

.sectionHeadDiv .sectionIconCSS .cardIcon {
  width: 36px;
  min-width: 36px;
}

.sectionHeadDiv .cardTitle {
  color: $dark-color;
  font-size: var(--fs-body) !important;
  @include mediumFontFamily;
  line-height: var(--line-height-sm);
  margin: 0;
  margin-right: 16px;
  word-break: break-word;
}

.p-tag-center {
  font-size: var(--fs-body) !important;
  @include mediumFontFamily;
  line-height: var(--line-height-sm);
  color: $dark-color;
  text-align: center;
}

.p-tag-left {
  font-size: var(--fs-body) !important;
  @include mediumFontFamily;
  line-height: var(--line-height-xl) !important;
  color: $dark-color;
  text-align: left;
}

@media screen and (min-width: 990px) {
  .p-tag-center {
    font-size: var(--fs-body) !important;
    line-height: var(--line-height-xl);
    padding: 0 12vw;
  }
}

.cardAbsolutePos {
  position: absolute;
  min-width: 100%;
  top: 20%;
  left: -35%;
  height: 60%;
  padding: 16px;
  background-image: url(./images/BannerMap_BG.png);
  @include background-size(100% 100%);
  box-shadow: $Box-Shadow4;
  backdrop-filter: blur(10.5px);
  border-radius: var(--br-primary);
}

.longBtn {
  width: 12rem;
}

@media screen and (min-width: 990px) {
  .cardSection {
    margin: 0.625rem 0rem;
    @include displayFlex(center, center, row);
    max-width: 34%;
  }
}

.speakExpertsSection {
  margin: 60px 5vw;
  background: $Linear-Gradient4;
  border: 3px solid rgba(103, 90, 253, 0.3) !important;
  border-radius: var(--br-primary) !important;
  position: relative;
}

.graphSectionCurl {
  position: absolute;
  top: -7%;
  right: -2%;
  height: 22%;
}

@media screen and (min-width: 900px) {
  .graphSectionCurl {
    position: absolute;
    height: 10%;
    width: auto;
    top: -1%;
    right: -3%;
  }
}

.cardPadding {
  padding: calc(2rem + 2vw) 0 !important;
}

.containerPadding {
  padding: 0px 70px;
}

@media screen and (min-width: 768px) {
  .cardPadding {
    padding: calc(3rem + 1vw) 0 !important;
  }
}

.faqBtn {
  width: 100%;
  text-align: left;
  background-color: transparent;
  border: 0px solid rgb(51, 51, 51);
  border-radius: var(--br-btn);
}

.h1-faqs {
  color: $Black-color;
  @include semiboldFontFamily;
  line-height: var(--line-height-sm);
  font-size: var(--fs-font2);
  margin-bottom: 20px;
}

/* Banner Home */

.hero-banner {
  width: 100% !important;
  left: 0;
  z-index: 3;
  color: $White-color;
  text-align: center;
  text-shadow: $Text-shadow;
  height: 45vh;
  margin-top: 0rem;
  padding: 16px;
}

.bannerTitle {
  @include semiboldFontFamily;
  font-size: var(--fs-h1);
  line-height: var(--line-height-sm);
  color: $light-color;
}

.bannerSubTitle {
  @include mediumFontFamily;
  font-size: var(--fs-body);
  line-height: var(--line-height-sm);
  color: $light-color;
}

.footerTitle {
  @include mediumFontFamily;

  font-size: var(--fs-h3);
  line-height: var(--line-height-sm);
  margin-top: 2vh;
  margin-bottom: 2vh;
  color: $primary-color-3;
}

.pFooterDesc {
  @include regularFontFamily;
  font-size: var(--fs-body);
  line-height: normal;
  color: $dark-color;
}

.footerLink a {
  @include regularFontFamily;
  font-size: var(--fs-body);
  line-height: var(--line-height-xxl);
  color: $dark-color;
  text-transform: unset;
}

.officeTimings,
.officeAddress,
.officeEMail {
  @include displayFlex(center, flex-start, row);
  @include regularFontFamily;
  font-size: var(--fs-body);
  line-height: var(--line-height-md);
  color: $dark-color;
}

.officeEMail {
  cursor: pointer;
}

.officeAddress {
  align-items: flex-start;
}

.socialLinks {
  margin-bottom: 1rem;
  @include displayFlex(stretch, center, row);
  margin-top: 3rem;
}

.socialIcon {
  width: var(--social-icons-width);
  cursor: pointer;
}

@media screen and (min-width: 991px) {
  .socialLinks {
    @include displayFlex(stretch, flex-start, row);
    margin-bottom: 1rem;
  }
}

.footerRights {
  @include mediumFontFamily;
  font-size: var(--fs-small);
  line-height: var(--line-height-xxl);
  text-align: center;
  color: $dark-color;
  border-top: 2px solid $light-color-2;
}

.brandLogoFooter {
  height: 6rem;
  cursor: pointer;
}

.mdMenuLeft a {
  @include mediumFontFamily;
  font-size: var(--fs-h5);
  line-height: var(--line-height-sm);
  color: $light-color;
  margin: 0;
  letter-spacing: 0em;
  text-transform: capitalize;
  text-decoration: none;
  padding: 0 16px;
}

.mdMenuRight:nth-child(1) a {
  @include mediumFontFamily;
  font-size: var(--fs-small);
  line-height: var(--line-height-sm);
  color: $White-color;
  margin: 0;
  letter-spacing: 0em;
  text-transform: capitalize;
  text-decoration: none;
  padding: 16px 32px;
  background: transparent;
  border-radius: $border-radius1;
  border: 0.893px solid $light-color-13;
  margin-right: 8px;
}

.mdMenuRight:nth-child(2) a {
  @include mediumFontFamily;
  font-size: var(--fs-small);
  line-height: var(--line-height-sm);
  color: $blue-color-1;
  margin: 0;
  letter-spacing: 0em;
  text-transform: capitalize;
  text-decoration: none;
  padding: 16px 32px;
  background: $White-color;
  border-radius: $border-radius1;
  border: 0.893px solid $light-color-13;
}

img.clipCSS {
  position: absolute;
  left: -52px;
  height: 150px;
  bottom: -85px;
}

.bannerHome {
  background-image: url(./images/LandingPage/banner_bg_md.png);
  @include background-size(100% 100%);
  height: calc(22rem + 4vw);
  --bs-gutter-x: 0rem;
}

@media screen and (min-width: 768px) {
  .bannerHome {
    height: calc(38rem + 1vw);
  }

  .bannerSubTitle {
    padding: 0 10%;
  }
}

/* Tablet view */

@media screen and (min-width: 768px) {
  .footerBG {
    padding: 8px 30px 0;
    background: url(./images/Footer/footer_bg_sm.png);
    background-size: 180vw calc(60% + 10vw) !important;
    background-repeat: no-repeat;
    background-position: center bottom;
    -moz-background-size: cover;
    -o-background-size: cover;
  }

  .speakExpertsSection {
    margin: 60px 4vw;
    padding: 40px 20px;
    background: $Linear-Gradient4;
    border: 3px solid rgba(103, 90, 253, 0.3) !important;
    @include background-size(100% 100%);
    border-radius: var(--br-primary);
    position: relative;
  }

  .addressContainer {
    margin: 60px 4vw 0px;
    max-width: 90%;
  }

  .footerTextColumn {
    margin-top: 2.5rem;
  }

  .brandLogoFooter {
    height: 6rem;
    margin-top: -2rem;
  }
}

/* Desktop View */

@media screen and (min-width: 1440px) {
  .bannerHome {
    height: calc(40rem + 4vw);
  }

  .bannerSubTitle {
    padding: 0 20%;
  }

  .footerTitle {
    @include displayFlex(flex-end, flex-start, row);
    @include mediumFontFamily;
    font-size: var(--fs-h4);
    line-height: var(--line-height-sm);
    margin-top: 0;
    color: $primary-color-3;
    min-height: 3rem;
    margin-bottom: 2rem;
  }

  .footerBG {
    padding: 8px 30px 0;
    background: url(./images/BannerMap_BG.png) bottom center;
    background-repeat: no-repeat;
    @include background-size(100vw 75%)
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px) {
  .cardAbsolutePos {
    position: static;
    min-width: 100%;
    top: 10%;
    left: -25%;
    height: 100%;
    padding: 16px;
    background-image: url(./images/BannerMap_BG.png);
    @include background-size(100% 100%);
    box-shadow: $Box-Shadow4;
    backdrop-filter: blur(10.5px);
    border-radius: var(--br-primary);
  }

  .hero-banner {
    padding: 32px 8px;
    width: 100% !important;
  }

  .containerPadding {
    padding: 0px 50px;
  }

  .pad-lft-50px {
    padding-left: 16px !important;
  }

  .headingCSS h1,
  .headingCSS h1 span.headingSpan {
    font-size: var(--fs-h2);
    line-height: var(--line-height-sm);
  }
}

@media screen and (min-width: 250px) and (max-width: 767px) {
  .banner-overlay {
    padding: 20px;
  }

  .cardAbsolutePos {
    position: static;
    min-width: 100%;
    top: unset;
    left: unset;
    height: 100%;
    padding: 16px;
    background-image: url(./images/BannerMap_BG.png);
    @include background-size(100% 100%);
    box-shadow: $Box-Shadow4;
    backdrop-filter: blur(10.5px);
    border-radius: var(--br-primary);
  }

  .hero-banner {
    padding: 0px 8px;
    width: 100% !important;
  }

  .containerPadding {
    padding: 0px 30px;
  }

  .pad-lft-50px {
    padding-left: 16px !important;
  }

  .speakExpertsSection {
    margin: 30px 0;
    padding: 30px 16px;
    background: $Linear-Gradient4;
    border: 3px solid rgba(103, 90, 253, 0.3) !important;
    @include background-size(100% 100%);
    border-radius: var(--br-primary);
    position: relative;
  }

  .headingCSS h1,
  .headingCSS h1 span.headingSpan {
    font-size: var(--fs-h1);
    line-height: var(--line-height-sm);
    padding: 0 1.5vw;
  }
}