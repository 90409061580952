@import "./_basics.scss";
@import "../CSS/variables.scss";

:root {
  --ff-primary: "ClashDisplay-Variable";
  --fs-h1: 1.5rem;
  --fs-h2: 1.5rem;
  --fs-h3: 1.25rem;
  --fs-h4: 1rem;
  --fs-h5: .8rem;
  --fs-body: 1rem;
  --fs-small: 0.8rem;
  --fs-h6: 1.2rem;
  --br-primary: 1rem;
  --br-secondary: 1.875rem;
  --br-btn: 0.875rem;
  --line-height-sm: 1.2;
  --line-height-md: 1.4;
  --line-height-xl: 1.58;
  --line-height-xxl: 2.25;
  --fs-font1: 1.7rem;
  --fs-font2: 1.5rem;
  --fs-font3: 40px;
  --fs-font4: 20px;
  --fs-font5: 13px;
  --fs-font6: 24px;
  --fs-font7: 2.5rem;
  --fs-font8: 22px;
  --fs-font9: 32px;

  --btn-height-primary: 3.25rem;
  --brand-logo-height: calc(2rem + 2vw);

  --social-icons-width: 2.75rem;
  --tertiary-glow: linear-gradient(97deg, #675afd 3.33%, #9c74ff 96.97%);
  --navbar-gradient: linear-gradient(248deg,
      #45108a 2.27%,
      #3d065f 29.57%,
      #10054d 101.35%);
}

/* for tablet */
@media (min-width: 768px) {
  :root {
    --fs-h1: 2.5rem;
    --fs-h2: 1.875rem;
    --fs-h3: 1.5625rem;
    --fs-h4: 1.2rem;
    --fs-h5: 1rem;
    --fs-body: 1rem;
    --fs-small: 0.8rem;
    --btn-height-primary: 3.5rem;
    --brand-logo-height: 3.5rem;
    --navbar-gradient: linear-gradient(312deg,
        #45108a 2.27%,
        #3d065f 29.57%,
        #10054d 101.35%);
  }
}

@media (min-width: 1024px) {
  :root {
    --social-icons-width: 3rem;
    --brand-logo-height: calc(3.5rem + 0.25vw);
  }
}

/* for Desktop */
@media (min-width: 1440px) {
  :root {
    --fs-h1: 3.5rem;
    --fs-h2: 3rem;
    --fs-h3: 2.25rem;
    --fs-h4: 2rem;
    --fs-h5: 1rem;
    --fs-body: 1.3125rem;
    --fs-small: 1.2rem;
    --navbar-gradient: linear-gradient(295deg,
        #45108a 2.27%,
        #3d065f 29.57%,
        #10054d 101.35%);
  }
}

/* for large Desktop */
@media (min-width: 1920px) {
  :root {
    --fs-h5: 1.2rem;
  }
}