@import "../../../CSS/variables.scss";

.left_curl {
  position: absolute;
  top: 42%;
  left: -22px;
  height: 52px;
}
.right_curl {
  position: absolute;
  top: 43%;
  right: -24px;
  height: 52px;
}

@media screen and (min-width: 768px) {
  .left_curl {
    position: absolute;
    top: 39%;
    left: -4%;
    height: 25%;
  }
  .right_curl {
    position: absolute;
    top: 40%;
    right: -3.5%;
    height: 8vw;
  }
}

@media screen and (min-width: 1024px) {
  .left_curl {
    position: absolute;
    top: 38%;
    left: -3vw;
    height: 5vw;
  }
  .right_curl {
    position: absolute;
    top: 39%;
    right: -3.5%;
    height: 5vw;
  }
}
@media screen and (min-width: 1440px) {
  .left_curl {
    position: absolute;
    top: 35%;
    left: -3vw;
    height: 5vw;
  }
  .right_curl {
    position: absolute;
    top: 39%;
    right: -3.5%;
    height: 5vw;
    transform: translate(-1px, -50%);
  }
}
.banner-lower {
  text-align: center;
}
.h1ExpertsSection {
  @include semiboldFontFamily;
  font-size: var(--fs-h2);
  line-height: normal;
  text-align: center;
  color: $light-color;
  margin-bottom: 8px;
}
.pExpertSections {
  @include mediumFontFamily;
  font-size: var(--fs-body);
  line-height: normal;
  text-align: center;
  color: $light-color;
  margin-bottom: 8px;
}
