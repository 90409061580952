@import "../../../CSS/variables.scss";

.feedApi_box {
  margin: 40px 0 0 0;
}

.feedApi_text {
  @include displayFlex(center, center, column);

  // For small screens (xs)
  margin: 20px 20px 0 20px;

  // For large screens (lg)
  @media (min-width: 1440px) {
    margin: 60px 76.8px 0 76.8px;
  }
}
