@import "../../../CSS/variables.scss";
@import "../../../Styles/global.scss";

.primaryBtnDemo {
  border-radius: var(--br-btn) !important;
  @include mediumFontFamily;
  font-size: var(--fs-h4) !important;
  line-height: var(--line-height-sm) !important;
  height: var(--btn-height-primary);
  width: 9.25rem;
  flex-shrink: 0;
}

.primaryBtnDemo.whiteBtn {
  background: $White-color;
  color: $primary-color-3;
}

.primaryBtnDemo.whiteBtn:hover {
  background: $White-color;
  color: $primary-color-3;
}

@media screen and (min-width: 768px) {
  .primaryBtnDemo {
    @include mediumFontFamily;
    font-size: var(--fs-font4) !important;
    line-height: var(--line-height-sm) !important;
    height: var(--btn-height-primary);
    width: 9.5rem;
    flex-shrink: 0;
  }

  .primaryBtnDemo.whiteBtn {
    height: var(--btn-height-primary);
    width: 12.5rem;
    flex-shrink: 0;
  }

  .cardSection {
    margin: 0.625rem 0rem;
  }
}