@import "../../CSS/variables.scss";

.bannerAbout {
  background: $Linear-Gradient5;
  background-image: url(../../images/AboutUs/Banner/banner_about_us_mb.png);
  @include background-size(100% 100%);
  --bs-gutter-x: 0;
  --bs-gutter-y: 0;
  height: 50vh;

  @media screen and (min-width: 768px) {
    background-image: none;
    background: $Linear-Gradient5;
    height: calc(18rem + 10vw);
    position: relative;
  }

  @media screen and (min-width: 1024px) {
    height: calc(24rem + 4vw);
    position: relative;
  }

  @media screen and (min-width: 1440px) {
    height: 28rem;
    position: relative;
  }
}

.digitalCircuitImage {
  @media screen and (min-width: 768px) {
    position: absolute;
    width: 65%;
    right: -1%;
    top: 8%;
  }

  @media screen and (min-width: 1024px) {
    position: absolute;
    width: 57%;
    right: 0%;
    top: -6%;
  }

  @media screen and (min-width: 1440px) {
    position: absolute;
    width: calc(40% + 5vw);
    right: 0%;
    top: -6%;
    max-width: 800px;
  }
}

.digitalCircuitLeft {
  @media screen and (min-width: 768px) {
    position: absolute;
    width: 24%;
    left: 0%;
    top: -9%;
    z-index: 1;
  }

  @media screen and (min-width: 1024px) {
    position: absolute;
    width: 24%;
    left: 0%;
    top: -9%;
    z-index: 1;
    max-width: 16rem;
  }
}

.bannerWithCircuitImage {
  @media screen and (min-width: 768px) {
    @include displayFlex(center, flex-start, row);
  }
}

.bannerTitle {
  text-align: center;
  padding: 0 0vw;

  @media screen and (min-width: 768px) {
    padding: 0 0vw;
  }
}

.larger-text {
  font-size: 32px;
}

.hero-banner-about {
  width: 100% !important;
  @include displayFlex(center, flex-start, column);
  text-shadow: $Text-shadow;
  padding: 16px 0;

  @media screen and (min-width: 768px) {
    width: 55% !important;
    @include displayFlex(flex-start, center, column);
    z-index: 3;
    height: 100%;
    color: $White-color;
    text-shadow: $Text-shadow;
    padding: 16px 0;
  }

  @media screen and (min-width: 1024px) {
    @include displayFlex(stretch, center, column);
  }
}

.bannerSubTitle {
  @media screen and (min-width: 768px) {
    margin-bottom: 2rem;
    padding: 0 0% !important;
  }
}

.text-align-left {
  @media screen and (min-width: 768px) {
    text-align: left;
  }
}

.cardBody {
  padding: 0rem !important;

  @media screen and (min-width: 768px) {
    padding: 0.4rem !important;
  }

  @media screen and (min-width: 1440px) {
    padding: 0.75rem !important;
  }
}

.cardBody-2 {
  @media screen and (min-width: 768px) {
    padding: 0.4rem !important;
  }
}

.sectionThree {
  background: #f4f8ff;
  position: relative;
}

.dataCollectionBG1 {
  background: $light-color-15;
  position: relative;
  @include background-size(100% 100%);

  @media screen and (min-width: 768px) {
    @include background-size(100% 100%);
  }

  @media screen and (min-width: 1024px) {
    @include background-size(100% 100%);
  }

  @media screen and (min-width: 1440px) {
    @include background-size(100% 100%);
  }
}

.dashboards_reportings_BG {
  background: $Linear-Gradient3,
    url(../../images/Dashboard_and_Reporting_BG.png);
  @include background-size(100% 100%);
}

.bannerUpperlavender {
  position: relative;
  padding: 50px 80px;
  background: $light-color-16;
  @include background-size(100% 100%);
  text-align: center;
  border-bottom: 2px solid $light-color-2;

  @media screen and (min-width: 768px) {
    padding: 50px 80px;
    @include background-size(100% 100%);
    text-align: center;
    border-bottom: 2px solid $light-color-2;
  }

  @media screen and (min-width: 1024px) {
    padding: 50px 80px;
    @include background-size(100% 100%);
    text-align: center;
    border-bottom: 2px solid $light-color-2;
  }

  @media screen and (min-width: 1440px) {
    padding: 50px 80px;
    @include background-size(100% 100%);
    text-align: center;
    border-bottom: 2px solid $light-color-2;
  }
}

.dotsGroupTopLeft {
  position: absolute;
  top: 0.5%;
  left: 2%;
  width: 6%;

  @media screen and (min-width: 768px) {
    position: absolute;
    top: 2%;
    left: 1%;
    width: 3%;
  }
}

.dotsGroupBottomRight {
  position: absolute;
  bottom: 0.5%;
  right: 2%;
  width: 6%;

  @media screen and (min-width: 768px) {
    position: absolute;
    bottom: 2%;
    right: 1%;
    width: 3%;
    max-width: 50px;
  }
}

.cardBanner {
  padding: 10px;
  height: 100% !important;
  z-index: 1;
  margin-bottom: 1rem;
  height: auto !important;

  @media screen and (min-width: 990px) {
    padding: 10px !important;
    height: 100% !important;
    width: calc(18rem + 7vw);
  }
}

.bannerUpper1 {
  padding: 50px 80px;
  background: $Black-color;
  @include background-size(100% 100%);

  @media screen and (min-width: 990px) {
    background: $Linear-Gradient3,
      url(../../images/Dashboard_and_Reporting_BG.png);
    @include background-size(100% 100%);
    border-bottom: 2px solid $light-color-2;
  }
}

.cardCenter1 {
  @include displayFlex(flex-start, center, column);
  height: 100%;
  text-align: left;
}

.cardView {
  margin-bottom: 3rem;

  @media screen and (min-width: 990px) {
    margin-bottom: 0rem !important;
    margin-top: 2.5rem !important;
  }
}