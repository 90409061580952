@import "../CSS/variables.scss";

html {
    scroll-behavior: smooth;
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

body {
    overflow-x: hidden;
    font-family: var(--ff-primary);
}

.green-bg {
    background-color: $light-green-color;
}

.secondary-bg {
    background-color: $primary-color-4;
}

.relativePosition {
    position: relative;
}

.scrollTop {
    position: fixed;
    z-index: 99;
    right: 4vw;
    bottom: 3vh;
    display: flex;
    flex-direction: row-reverse;
    cursor: pointer;
}

.scrollTopIcon {
    width: 60%;
}

.headingCSS {
    position: relative;

    h1 {
        @include semiboldFontFamily;
        font-size: var(--fs-h2);
        line-height: var(--line-height-sm);
        text-align: center;
        color: $dark-color;
        text-transform: unset;

        span.headingSpan {
            @include semiboldFontFamily;
            font-size: var(--fs-h2);
            line-height: var(--line-height-sm);
            text-align: center;
            color: $primary-color-3;
            letter-spacing: 1px;
            text-transform: unset;
        }
    }
}

.mar-top-30px {
    margin-top: 30px;
}

.mar-btm-15px {
    margin-bottom: 15px;
}

.mar-btm-30px {
    margin-bottom: 30px;
}

.mar-btm-20px {
    margin-bottom: 20px;
}

.mar-btm-25px {
    margin-bottom: 25px;
}

.mar-right-8px {
    margin-right: 16px;
}

.pad-lft-50px {
    padding-left: 50px !important;
}

.padding-zero {
    padding: 0 !important;
}

.padding-20px {
    padding: 16px;
}

.padding-12px {
    padding: 12px;
}

.margin-none {
    margin: 0;
}

.pad-all-md {
    padding: 0px 35px;
}

.paddingLeft {
    padding-left: 35px;
}

.paddingRight {
    padding-right: 35px;
}

.height-100 {
    height: 100% !important;
}

.pad-top-md {
    padding-top: 40px;
}

.absImage {
    position: absolute;
    left: 20px;
    bottom: 20px;
}

.pad-x-sm {
    padding: 0px 0.5rem;
}

.dividerFooter {
    margin: 0 1rem;
}

.topBannerClip {
    position: absolute;
    top: 26%;
    right: 2%;
    width: 26%;
    z-index: 2;
}

.bottomBannerClip {
    position: absolute;
    bottom: 20%;
    left: 3%;
    width: 26%;
    z-index: 2;
}

.bottomRightBannerClip {
    position: absolute;
    bottom: -4%;
    right: 6%;
    width: 17%;
    z-index: 2;
}

.container-rotation {
    position: relative;
    /* @include displayCenter;*/
    @include displayFlex(center, center, row);
    width: 80%;
}

.animationCircle {
    position: absolute;
    width: 110%;
    top: 6%;
    z-index: -1;
}

.container-real-time {
    position: relative;
    width: 90%;
}

.realTimeChart {
    position: absolute;
    width: 40%;
    right: 0%;
    top: 26%;
}

.realStatShow {
    position: absolute;
    width: 40%;
    left: 0%;
    top: 30%;
}

.realTimeEllipse1 {
    position: absolute;
    width: 22%;
    left: -10%;
    bottom: -2%;
}

.realTimeEllipse2 {
    position: absolute;
    width: 22%;
    right: -9%;
    top: -5%;
}

/* parts for FAQ */
.container-FAQ-image {
    position: relative;
    /* @include displayCenter;*/
    @include displayFlex(center, center, row);
    width: 100%;
}

.FAQGraph {
    position: absolute;
    width: 39%;
    right: 0%;
    top: 14%;
}

.dashboardFAQ {
    position: absolute;
    width: 50%;
    left: -7%;
    top: 11%;
}

.FAQColorCircle {
    position: absolute;
    width: 25%;
    right: -9%;
    top: -9%;
    z-index: -1;
}

.FAQPieChart {
    position: absolute;
    width: 33%;
    left: -2%;
    bottom: 6%;
}