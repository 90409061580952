@import "../../CSS/variables.scss";
@import "../../Styles/global.scss";

.navBarCss {
  background: $Navbar-Gradient;
  width: 100%;
  height: auto;
  padding: 12px 0 0;
  @include displayFlex(center, flex-start, column);
}

.navBarCssAbout {
  background: var(--navbar-gradient);
  @include background-size(100% 100%);
  width: 100%;
  height: auto;
  padding: 12px 0 0;
  @include displayFlex(center, flex-start, column);
}

.navBarCssBlogs {
  background: $skyBlue-background;
  @include background-size(100% 100%);
  width: 100%;
  height: auto;
  padding: 12px 0 0;
  @include displayFlex(center, flex-start, column);
}

.navBarCss h6 {
  color: $White-color;
}

@media screen and (min-width: 768px) {
  .navBarCssAbout {
    @include background-size(100% 100%);
  }
}

@media screen and (min-width: 1024px) {
  .navBarCssAbout {
    @include background-size(100% 100%);
  }
}

@media screen and (min-width: 1440px) {
  .navBarCssAbout {
    background: var(--navbar-gradient);
    @include background-size(100% 100%);
  }
}

.navbarWidth {
  width: 100% !important;
  height: auto !important;
}

.navBarBorder {
  padding-bottom: 12px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.element {
  flex-grow: 1;

  // For small screens (xs)
  display: flex !important;

  // For medium and larger screens (md and up)
  @media (min-width: 1020px) {
    display: none !important;
  }
}

.mMatrixLogo {
  height: var(--brand-logo-height);
}

@media screen and (min-width: 1024px) {
  .mMatrixLogo {
    height: var(--brand-logo-height);
    min-height: 3.5rem;
    margin-left: 6vw;
  }
}

.navbar_box {
  flex-grow: 1;
  justify-content: flex-end;

  // For small screens (xs)
  display: flex;

  // For medium and larger screens (md and up)
  @media (min-width: 1020px) {
    display: none;
  }
}

.whiteColor {
  color: $White-color !important;
}

.menu-appbar1 {
  display: block;

  @media (min-width: 1020px) {
    display: none;
  }
}

.navbar_desk {
  flex-grow: 1;
  justify-content: space-between;
  width: 80%;
  display: none;

  @media (min-width: "1020px") {
    display: flex;
  }
}

.navbar_link {
  @include displayFlex(center, center, row);
  width: calc(20rem + 50vw);
}

.navbar_right_btn {
  @include displayFlex(center, flex-end, row);
  margin-left: 1rem;
  min-width: calc(16rem + 4vw);
}