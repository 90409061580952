@import "../../Styles/global.scss";
@import "../../CSS/variables.scss";

.bannerBlog {
  background: $skyBlue-background;
  padding: 3rem !important;
}

.blogInfoSection {
  display: flex;
  flex-wrap: wrap;
  margin-top: 12px;
  margin-bottom: 12px;

  .infoProfile {
    span {
      color: $dark-color;
      @include mediumFontFamily;
      font-size: var(--fs-font5);
      line-height: 30.642px;
      margin-right: 4px;
    }

    img {
      width: 32px !important;
      margin-right: 4px;
    }
  }
}

.blogSlider {
  .blogInfoSection {
    .infoProfile {
      span {
        color: $dark-color;
        @include mediumFontFamily;
        font-size: var(--fs-font5);
        line-height: 1.2;
        margin-left: 4px;
        margin-right: 4px;
      }
    }
  }
}

.flexCenterDiv {
  @include displayFlex(center, flex-start, row);
  flex: 1;
}

.blogTitle {
  color: $dark-color;
  @include mediumFontFamily;
  font-size: var(--fs-font6);
  line-height: 26.81px;
  overflow: hidden;
}

.BlogCardDesc {
  margin: 8px;
  box-shadow: unset;
}

@keyframes gradientTransition {
  0% {
    background-position: 0% 50%;
  }

  100% {
    background-position: 100% 50%;
  }
}

.blogsSingleCard {
  border-radius: $border-radius2 !important;
  border: 2.25px solid $light-color-3 !important;
  background: $White-color;
  @include displayFlex(flex-start, center, column);
  text-align: left;
  padding: 16px;
  box-shadow: none;
}

.blogsCard {
  border-radius: $border-radius2 !important;
  border: 2.061px solid $White-color !important;
  background: $White-color;
  @include displayFlex(flex-start, center, column);
  height: 100% !important;
  text-align: left;
  padding: 16px 16px 32px;
  box-shadow: $Box-Shadow3;

  a {
    text-decoration: none;
    color: $primary-color-3;
  }

  &:hover {
    border: 3px solid rgba(210, 182, 255, 0.3);
    background: $White-color;
    box-shadow: $Box-Shadow1;
  }

  .card-body {
    width: 100%;
  }

  .blogImage {
    overflow: hidden;

    img {
      opacity: 1;
      -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
      transition: opacity 0.35s, transform 0.35s;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }

  &:hover .blogImage img {
    opacity: 0.7;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  .blogsSingleCard .card-body {
    width: 100%;
  }
}

.blogImage_div {
  flex-shrink: 0 !important;
  height: auto !important;
  width: 100% !important;
}

.blogImage img {
  border-radius: $border-radius3;
  background: $Linear-Gradient2;
}

.hero-banner-blog {
  width: 100% !important;
  @include displayFlex(stretch, center, column);
  z-index: 3;
  height: 100%;
  color: $White-color;
  text-shadow: none;
  padding: 16px 0 0;
}

.blogDescription {
  color: $dark-color;
  @include regularFontFamily;
  font-size: var(--br-primary);
  line-height: 1.4;
  margin: 2rem 0 3rem;
  padding: 0 4px;

  p {
    img {
      overflow: hidden;
      width: 100%;
    }
  }
}

.blogSingleTitle {
  color: $dark-color;
  @include semiboldFontFamily;
  font-size: var(--fs-font7);
  line-height: normal;
  margin-bottom: 0;
}

.hrTitle {
  background: $border-color-20;
  height: 1px;
  margin: 1.5rem 0;
}

.blogsSingleSection_BG {
  padding: 1.5rem 0 !important;
  background: $Linear-Gradient3, url(../../images/Blog_Single_BG.png);
  background-repeat: no-repeat;
  @include background-size(100% 100%);
}

.blogSlider {
  .slick-slide {
    img {
      width: 100%;
      display: block;
      border-radius: $border-radius3;
    }
  }
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: var(--fs-font4);
  line-height: 1;
  opacity: 0.75;
  color: transparent !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.nextSlidde img,
.prevSlidde img,
.slick-prev img,
.slick-next img {
  width: 50px;
}

.justifySpaceBetween {
  @include displayFlex(center, space-between, row);
  width: 100%;
  padding: 0 2px;
}

.viewAllLink {
  color: $dark-color !important;
  text-align: center;
  @include regularFontFamily;
  font-size: var(--fs-font8);
  line-height: normal;
  text-decoration: none;
}

.relativePositionCSS {
  position: relative;
}

.absolutePositionCSS {
  position: absolute;
  top: 25px;
  height: 120px;
  left: 20px;
}

.bullet_bottom {
  position: absolute;
  right: 20px;
  bottom: 25px;
  height: 120px;
}

.blogs_loading_div {
  @include displayFlex(flex-start, center, row);
  flex-grow: 1 !important;
  background-color: $White-color !important;
  height: 100% !important;
  width: 100% !important;
}

@media screen and (min-width: 768px) {
  .hero-banner-blog {
    width: 100% !important;
    @include displayFlex(flex-start, center, column);
    z-index: 3;
    height: 100%;
    color: $White-color;
    text-shadow: none;
    padding: 16px 0;
  }
}

.cardPadding {
  padding: 30px 0;
}

.bannerTitle {
  text-align: center;
  padding: 0 0vw;
}

.larger-text {
  font-size: var(--fs-font9);
}

@media screen and (min-width: 768px) {
  .bannerTitle {
    padding: 0 1vw;
  }

  .text-align-left {
    text-align: left;
  }
}