@import "../../CSS/variables.scss";

.feedApi--hero-container {
  margin: 0 76.8px 0 76.8px;
  height: 100%;
  min-height: 410px;
  @include displayFlex(center, center, row);

  @media (max-width: 600px) {
    margin: 0;
  }
}

.feedApi--hero-content {
  @include displayFlex(center, space-between, row);
  max-width: 1440px;
  min-height: 410px;

  @media (max-width: 960px) {
    @include displayFlex(center, center, column);
  }
}

.feedApi--hero-text {
  @include displayFlex(flex-start, center, column);
  width: 50%;
  height: 100%;
  margin: 1rem 0px 0px 64px;

  @media (max-width: 960px) {
    width: 80%;
    margin: 3rem 0 0 0;
    @include displayFlex(center, center, column);
  }

  @media (min-width: 1280px) {
    margin-left: 0;
  }
}

.feedApi--hero-image {
  @include displayFlex(flex-end, flex-end, row);
  width: 50%;

  @media (max-width: 960px) {
    justify-content: space-around !important;
  }
}

.feedApi--hero-section {
  background: $Linear-Gradient5;
  background-size: 100% 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  --bs-gutter-x: 0;
  --bs-gutter-y: 0;
}

.heroTitleFeedApi {
  padding: 0 !important;
}

.heroImage--feedApi {
  width: 100%;
  margin-bottom: 20px;
}

.subtitle--feedapi {
  width: 100%;
  text-align: center;
}

@media (width > 678px) {
  .heroImage--feedApi {
    width: 75%;
  }
}

@media (width > 900px) {
  .heroImage--feedApi {
    width: 50%;
    max-height: 430px;
  }

  .subtitle--feedapi {
    width: 80%;
    text-align: left;
  }

  .feedApi--hero-section {
    min-height: 410px;
  }

  .feedApi--cardSection--heading {
    margin-left: 8%;
  }
}

@media (width > 1400px) {
  .feedApi--cardSection--heading {
    margin-left: 0%;
  }
}

.feedApi_page {
  @include displayFlex(center, center, column);

  // For small screens (xs)
  margin: 20px 20px 0 20px;

  // For large screens (lg)
  @media (min-width: 1440px) {
    margin: 60px 76.8px 0 76.8px;
  }
}

.feedApi_heading {
  text-align: start !important;
}