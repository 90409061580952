@import "../../../CSS/variables.scss";

.bannerDiv {
  @include displayFlex(stretch, center, row);
  height: 70%;
}

.homeBannerLowerSEction {
  background: $Linear-Gradient3,
    url(../../../images/mMatrix_Dashbord_BG_New.png);
  @include background-size(cover);
  position: relative;
}

.leftSpring {
  top: -82%;
  position: absolute;
  @include displayFlex(stretch, center, row);
  width: 6%;
  left: 5%;
}

.rightSpring {
  top: -82%;
  position: absolute;
  @include displayFlex(stretch, center, row);
  width: 9%;
  right: 5%;
}

.imageCut {
  top: -80%;
  position: absolute;
  @include displayFlex(stretch, center, row);
  width: 95%;
}

.imgFlexCenter {
  @include displayFlex(stretch, center, row);
  position: relative;
}

.leftCirle {
  position: absolute;
  bottom: 6%;
  left: 10%;
  width: 11vw;
}

.rightCirle {
  position: absolute;
  top: 8%;
  right: 8%;
  width: 11vw;
}

.imgBanner {
  max-width: 90% !important;
  z-index: 1 !important;
}