@import "../../CSS/variables.scss";
@import "../../Styles/global.scss";

.accordionHead {
  padding: 0px !important;
  border-radius: var(--br-primary) !important;
  border: 2px solid $light-Color-6 !important;
  background: $White-color !important;
  box-shadow: none !important;
  font-size: var(--fs-body) !important;
  @include mediumFontFamily;
  color: $dark-color;
}

.accordionHead.activeAccodion {
  color: $primary-color-3 !important;
  border: 2px solid $light-Color-6 !important;
  background: $light-color-14 !important;
  box-shadow: $Box-Shadow2 !important;
  border-radius: var(--br-primary) var(--br-primary) 0 0 !important;
}

.accordionHead.activeAccodion .btnFaqsBlock {
  color: $primary-color-3 !important;
}

.btnFaqsBlock {
  @include displayFlex(center, space-between, row);
  width: 100%;
  font-size: var(--fs-body) !important;
  @include mediumFontFamily;
  text-transform: unset;
  text-decoration: none !important;
  color: $dark-color !important;
  padding: 16px !important;
}

.btnFaqsBlock span {
  text-align: left;
}

.accordionContent {
  background: $backgroundColor-light-6 !important;
  border: 2px solid $light-Color-6 !important;
  box-shadow: none !important;
  color: $dark-color-5;
  font-size: var(--fs-body);
  @include regularFontFamily;
  line-height: var(--line-height-xl);
  border-radius: 0px 0px var(--br-primary) var(--br-primary);
}

.faqText {
  color: $dark-color-5;
  @include mediumFontFamily;
  margin-left: 1rem;
  line-height: var(--line-height-xl);
  padding: 0.5rem 0.5rem 0.5rem 0;
}